import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "userhome" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_van_tabbar_item = _resolveComponent("van-tabbar-item")!
  const _component_van_tabbar = _resolveComponent("van-tabbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        (_openBlock(), _createBlock(_KeepAlive, null, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
        ], 1024))
      ]),
      _: 1
    }),
    _createVNode(_component_van_tabbar, {
      modelValue: _ctx.active,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.active) = $event)),
      route: ""
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabbars, (item, index) => {
          return (_openBlock(), _createBlock(_component_van_tabbar_item, {
            key: index,
            icon: item.icon,
            to: item.path,
            replace: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(item.name), 1)
            ]),
            _: 2
          }, 1032, ["icon", "to"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}