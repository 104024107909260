
import { Options, Vue } from "vue-class-component";
import { Action } from "vuex-class";
@Options({
  components: {},
})
export default class UserHome extends Vue {
  private active = 0;
  private tabbars = [
    // {
    //   path: "/sampleReceptionH5",
    //   name: "样品接收",
    //   icon: "records"
    // },
    // {
    //   path: "/userCollectGoods",
    //   name: "样本采集",
    //   icon: "completed"
    // },
    {
      path: "/sceneSpecimenListH5",
      name: "样本管理",
      icon: "completed",
    },
    // {
    //   path: "/locphodemo",
    //   name: "Demo",
    //   icon: "completed",
    // },
    {
      path: "/userinfo",
      name: "我的",
      icon: "user-o",
    },
  ];
}
